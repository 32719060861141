
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import State from "@/components/ABilling/StateComponent.vue";
import FileUpload from "@/components/ABilling/FileUpload.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import {
  getStatementSettings,
  updateStatementSettings,
} from "@/api/statementSettings.api";
import { useStore } from "vuex";
import { email, helpers, maxLength, required } from "@vuelidate/validators";

export interface Data {
  isDirty: boolean;
  settings?: any;
  logo: any;
  img?: any;
  cardTypes?: [];
}
export default defineComponent({
  name: "Provider Page",
  components: { State, FileUpload, Multiselect, PhoneNumberComponent },
  props: ["providerId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },

  setup(props) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      settings: undefined,
      logo: null,
    });

    const settingsRules = {
      settings: {
        remitName: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
        remitAddress1: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
        remitAddress2: {
          maxLength: maxLength(50),
        },
        remitCity: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
        remitState: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
        remitPostal: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
        billingEmail: {
          email,
          maxLength: maxLength(42),
        },
        billingPhone: {
          maxLength: maxLength(50),
        },
        billingFax: {
          maxLength: maxLength(50),
        },
        officeHours: {
          maxLength: maxLength(42),
        },
        url: {
          maxLength: maxLength(56),
        },
        businessName: {
          maxLength: maxLength(42),
        },
        defaultMessage: {
          maxLength: maxLength(250),
        },
      },
    };

    let v$ = useVuelidate(settingsRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Statement Settings");
      data.cardTypes = store.getters.allCardTypes;
      let statementSettings = await getStatementSettings();
      if (!statementSettings) {
        statementSettings = { creditCardBox: false };
      }
      if (statementSettings.cardTypes) {
        statementSettings.cardTypes = statementSettings.cardTypes.split(";");
      }

      data.settings = statementSettings;
    });

    async function updateItem() {
      const result = await v$.value.settings.$validate();
      if (result) {
        var formData = new FormData();
        Object.keys(data.settings).forEach((key) => {
          if (data.settings[key]) formData.append(key, data.settings[key]);
        });
        let cartTypesStr = "";
        if (data.settings.cardTypes) {
          data.settings.cardTypes.forEach((item) => {
            cartTypesStr += item + ";";
          });
        }
        formData.delete("cardTypes");
        formData.append("cardTypes", cartTypesStr.slice(0, -1));

        await updateStatementSettings(formData);
      }
    }
    function getUploadedData(file) {
      data.settings.logo = file.blob;
    }

    return {
      organizationId,
      updateItem,
      data,
      getUploadedData,
      v$,
    };
  },
});
